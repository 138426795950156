import React from "react";
import { string, node, oneOfType, arrayOf } from "prop-types";

const Hero = (props) => {
  const {
    headline,
    subline,
    section,
    children,
    styleClass = `mb-5`,
    bgColor = `skyblue`,
  } = props;

  return (
    <div className={`hero text-color-lg-white mt-4 mt-lg-0 ${styleClass}`}>
      <header className="hero-heading mb-4">
        <div className="container">
          <h1 className="h5">{section}</h1>
          <h2 className="text-size-2 text-size-md-1">{headline}</h2>
          <p className="text-size-4 text-size-md-3">{subline}</p>
        </div>
      </header>
      <div className="content-container">
        <div className={`bg-gradient bg-monday-${bgColor}`}>{children}</div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  headline: string.isRequired,
  subline: string.isRequired,
  section: string.isRequired,
  styleClass: string,
  bgColor: string,
  children: oneOfType([node, arrayOf(node)]).isRequired,
};

export default Hero;
